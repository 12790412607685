html {
  height: -webkit-fill-available;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Kanit', sans-serif;
  min-height: -webkit-fill-available;
}
body, body #root{
  background-color: #f8f8f8;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 300px white inset !important;
}

/* @media (max-width: 600px){
  div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.select-menu{
    width: 100vw;
    height: 100vh;
    max-width: unset !important;
    max-height: unset !important;
    left: unset !important;
    top: unset !important;
    transform: translate(50%, 50%) !important;
  }
}

@media (max-width: 960px){
  div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.select-menu{
    width: calc(100% - 18px);
    width: 100vw;
    max-width: 600px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
} */


@media(min-width: 769px) {
  html {
    font-size: 10.4px;
  }
  /* body {
    zoom: 0.65
  } */
}
@media(min-width: 1024px) {
  html {
    font-size: 11.2px;
  }
  /* body {
    zoom: 0.7
  } */
}
@media(min-width: 1280px) {
  html {
    font-size: 12.6px;
  }
  /* body {
    zoom: 0.85
  } */
}
@media(min-width: 1367px) {
  html {
    font-size: 15.2px;
  }
  /* body {
    zoom: 0.95
  } */
}
@media(min-width: 1500px) {
  html {
    font-size: 16px;
  }
  /* body {
    zoom: 1
  } */
}
